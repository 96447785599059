<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.payments_report") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Отчёты платежей"
          name="Отчёты платежей.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'transaction'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <div
      style="text-align: center"
      class="col-9 align-items-center align-self-center text-right pr-0 mb-2 m-d-flex m-sm-1 w-reset"
    >
      <span class="align-items-center align-self-center pr-2">
        <b
          ><el-tag
            >{{ $t("message.today_transactions") }}:
            {{ today_transactions.total | formatMoney }}
          </el-tag></b
        >
      </span>
      <span
        v-for="item in paymentTypes"
        :key="item.id"
        class="align-items-center align-self-center pr-2"
      >
        <b
          ><el-tag
            >{{ item.name | uppercaseFirst }}:
            {{ today_transactions[item.id] | formatMoney }}
          </el-tag></b
        >
      </span>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.order_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.payment_type_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.type"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.date"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.order_id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.order_id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient.show">
            <el-input
              clearable
              size="mini"
              v-model="filterForm.patient"
              :placeholder="columns.patient.title"
            ></el-input>
          </th>
          <th v-if="columns.payment_type_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.payment_type_id.title"
              size="mini"
              v-model="filterForm.payment_type_id"
            >
              <el-option
                v-for="item in paymentTypes"
                :key="item.id"
                :label="item.name | uppercaseFirst"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.type.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.type.title"
              size="mini"
              v-model="filterForm.type"
            >
              <el-option
                :label="$t('message.refund')"
                value="refund"
              ></el-option>
              <el-option
                :label="$t('message.payment')"
                value="payments"
              ></el-option>
              <el-option
                :label="$t('message.expence')"
                value="expence"
              ></el-option>
              <el-option :label="$t('message.bonus')" value="bonus"></el-option>
              <el-option
                :label="$t('message.hospitalization')"
                value="hospitalization"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.price.show">
            <el-input
              size="mini"
              v-model="filterForm.price"
              :placeholder="columns.price.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.date.show">
            <el-date-picker
              v-model="filterForm.date"
              :placeholder="columns.date.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            >
            </el-date-picker>
          </th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="transaction in list" :key="transaction.id">
          <td v-if="columns.id.show">{{ transaction.id }}</td>

          <td
            v-if="
              columns.order_id.show &&
              transaction.transactionable_type != 'bonus' &&
              transaction.transactionable_type != 'hospitalization'
            "
          >
            {{
              transaction.transactionable
                ? transaction.transactionable.paymentable_id
                : ""
            }}
          </td>
          <td
            v-if="
              columns.order_id.show &&
              transaction.transactionable_type == 'bonus'
            "
          >
            {{ transaction.bonus.part_id }}
          </td>
          <td
            v-if="
              columns.order_id.show &&
              transaction.transactionable_type == 'hospitalization'
            "
          >
            {{
              transaction.transactionable
                ? transaction.transactionable.patient_history
                  ? transaction.transactionable.patient_history.number
                  : ""
                : ""
            }}
          </td>
          <td
            v-if="
              columns.patient.show &&
              (transaction.transactionable_type == 'payments' ||
                transaction.transactionable_type == 'refund') &&
              transaction.transactionable_type != 'hospitalization'
            "
          >
            {{
              transaction.patient
                ? transaction.patient.surname +
                  " " +
                  transaction.patient.first_name
                : ""
            }}
          </td>
          <td
            v-if="
              columns.patient.show &&
              transaction.transactionable_type == 'hospitalization'
            "
          >
            {{
              transaction.patient
                ? transaction.patient.surname +
                  " " +
                  transaction.patient.first_name
                : ""
            }}
          </td>
          <td
            v-if="
              columns.patient.show &&
              transaction.transactionable_type == 'costs'
            "
          >
            {{
              transaction.transactionable
                ? transaction.transactionable.name
                : ""
            }}
          </td>
          <td
            v-if="
              columns.patient.show &&
              transaction.transactionable_type == 'bonus'
            "
          >
            {{
              transaction.bonus.part == "partner_clinics"
                ? transaction.bonus.clinic.name
                : ""
            }}
            {{
              transaction.bonus.part == "doctors"
                ? transaction.bonus.own_doctor.surname +
                  " " +
                  transaction.bonus.own_doctor.name
                : ""
            }}
            {{
              transaction.bonus.part == "partner_doctors"
                ? transaction.bonus.doctor.name
                : ""
            }}
          </td>
          <td v-if="columns.payment_type_id.show">
            {{ transaction.payment_type ? transaction.payment_type.name : "" }}
          </td>
          <td v-if="columns.type.show">
            <p v-if="transaction.transaction_type == 'payments'">
              {{ $t("message.payment") }}
            </p>
            <p v-if="transaction.transaction_type == 'refund'">
              {{ $t("message.refund") }}
            </p>
            <p v-if="transaction.transaction_type == 'costs'">
              {{ $t("message.expence") }}
            </p>
            <p v-if="transaction.transaction_type == 'bonus'">
              {{ $t("message.bonus") }}
            </p>
            <p v-if="transaction.transaction_type == 'hospitalization'">
              {{ $t("message.hospitalization") }}
            </p>
          </td>

          <td v-if="columns.price.show">
            {{ transaction.price | formatMoney }}
          </td>
          <td
            v-if="
              columns.date.show &&
              transaction.transactionable_type == 'payments'
            "
          >
            {{
              transaction.order.length ? transaction.order[0].created_at : ""
            }}
          </td>
          <td
            v-if="
              columns.date.show && transaction.transactionable_type == 'costs'
            "
          >
            {{ transaction.transactionable.created_at }}
          </td>

          <td
            v-if="
              columns.date.show && transaction.transactionable_type == 'bonus'
            "
          >
            {{ transaction.bonus.created_at }}
          </td>
          <td
            v-if="
              columns.date.show &&
              transaction.transactionable_type == 'hospitalization'
            "
          >
            {{
              transaction.transactionable
                ? transaction.transactionable.created_at
                : ""
            }}
          </td>
          <td v-if="columns.created_at.show">
            {{ transaction.created_at }}
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./components/crm-update";
import CrmFind from "./components/crm-find";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "PatientServiceList",
  components: {
    CrmUpdate,
    CrmFind,
  },
  data() {
    return {
      loadTable: false,
      tabAddPayment: false,
      drawerUpdate: false,
      drawerFind: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      today_transactions: 0,
    };
  },
  computed: {
    ...mapGetters({
      list: "transaction/reports",
      columns: "transaction/columns",
      pagination: "transaction/pagination",
      filter: "transaction/filter",
      sort: "transaction/sort",
      paymentTypes: "paymentTypes/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    if (this.paymentTypes && this.paymentTypes.length === 0)
      await this.paymentType();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      paymentType: "paymentTypes/index",
      updateList: "transaction/reports",
      updateSort: "transaction/updateSort",
      updateFilter: "transaction/updateFilter",
      updateColumn: "transaction/updateColumn",
      updatePagination: "transaction/updatePagination",
      refreshData: "transaction/refreshData",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.today_transactions = res.data.today_transactions;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    paymentTypePrices(itemId) {
      let price = 0;
      this.list.forEach((element) => {
        if (element.payment_type != null && element.payment_type.id == itemId) {
          price = price + parseFloat(element.price);
        }
      });
      return price;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          //   this.filterForm = JSON.parse( JSON.stringify( this.filter ))
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];

          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "patient_id":
                break;
              case "payment_type_id":
                this.excel_fields[column.title] = {
                  field: "payment_type",
                  callback: (patient) => {
                    return patient != "" ? patient.name : "";
                  },
                };
                break;
              case "patient":
                this.excel_fields[column.title] = {
                  field: "patient",
                  callback: (patient) => {
                    return patient != ""
                      ? patient.first_name + " " + patient.surname
                      : "";
                  },
                };
                break;
              case "type":
                this.excel_fields[column.title] = {
                  callback: (transaction) => {
                    console.log(transaction.transaction_type);
                    if (transaction.transaction_type == "payments")
                      return "Платеж";
                    if (transaction.transaction_type == "refund")
                      return "Возврат";
                    if (transaction.transaction_type == "costs")
                      return "Расход";
                    if (transaction.transaction_type == "bonus") return "Бонус";
                  },
                };
                break;
              case "order_id":
                this.excel_fields[column.title] = {
                  field: "order",
                  callback: (transaction) => {
                    return transaction[0] ? transaction[0].id : "";
                  },
                };
                break;

              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style >
#tr_pointer {
  cursor: pointer;
}
</style>

